import React, { Fragment, useEffect, useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import classNames from "classnames"

import AuthActions from "./Navbar/AuthActions"
import AuthMobileUser from "./Navbar/AuthMobileUser"
import AuthActionsMobile from "./Navbar/AuthActionsMobile"

import { getSignedInUser } from "../../components/Auth/services/user"
import { handleSignOut } from "../../components/Auth/services/signout"

import styles from "./utils/layout.module.scss"

const Navbar = ({ pageContext }) => {
  const data = useStaticQuery(graphql`
    {
      logoLong: file(relativePath: { eq: "logos/medgrocer-long.png" }) {
        childImageSharp {
          fixed(height: 25) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      logoCircle: file(relativePath: { eq: "logos/medgrocer-square.png" }) {
        childImageSharp {
          fixed(height: 25) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const logoLong = data.logoLong.childImageSharp.fixed
  const logoCircle = data.logoCircle.childImageSharp.fixed

  const [isMenuActive, setMenuActive] = useState(false)
  const [user, setUser] = useState({})
  const [userData, setUserData] = useState({ roles: [] })
  const [homeUrl, setHomeUrl] = useState(null)
  const [medstaffData, setMedstaffData] = useState({})

  useEffect(() => {
    const { accessRule, userData, medstaffData, authUser } = getSignedInUser()
    setHomeUrl(accessRule?.homeUrl || "/services")
    setUserData(userData)
    setMedstaffData(medstaffData)
    setUser(authUser)
  }, [])

  const handleMenuActive = () => setMenuActive(!isMenuActive)

  const Logo = () => {
    switch (true) {
      case pageContext?.disableNavbarElements:
        return (
          <div className={classNames(styles["navbar__brandLogos"])}>
            <a href={homeUrl}>
              <Img fixed={logoLong} className="is-hidden-mobile" />
              <Img fixed={logoCircle} className="is-hidden-tablet" />
            </a>
          </div>
        )
      case !!homeUrl:
        return (
          <button
            onClick={() => {
              window.location.href = homeUrl
            }}
            style={{ backgroundColor: "transparent", border: "none" }}
          >
            <div className={classNames(styles["navbar__brandLogos"])}>
              <Img fixed={logoLong} className="is-hidden-mobile" />
              <Img fixed={logoCircle} className="is-hidden-tablet" />
            </div>
          </button>
        )
      default:
        return (
          <Link to="/">
            <div className={classNames(styles["navbar__brandLogos"])}>
              <Img fixed={logoLong} className="is-hidden-mobile" />
              <Img fixed={logoCircle} className="is-hidden-tablet" />
            </div>
          </Link>
        )
    }
  }

  return (
    <Fragment>
      <nav className="navbar is-fixed-top is-light has-background-white">
        <div className={classNames("ml-1", styles["navbar__brand"])}>
          <Logo />

          <div className={styles["navbar__burgerContainer"]}>
            {!pageContext?.disableNavbarElements && (
              <AuthActionsMobile
                user={user}
                userData={userData}
                medstaffData={medstaffData}
                isMenuActive={isMenuActive}
              />
            )}
            {!!user && (
              <span
                onClick={handleMenuActive}
                aria-label="menu"
                aria-expanded="false"
                className="icon is-clickable"
                role="button"
                onKeyDown={(event) => {
                  if (event.key === "Enter") handleMenuActive()
                }}
                tabIndex={0}
              >
                <div className={styles["navbar__wrapper"]}>
                  {isMenuActive ? (
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M19.5467 17.9531C19.6516 18.0576 19.7348 18.1818 19.7916 18.3186C19.8484 18.4553 19.8776 18.6019 19.8776 18.75C19.8776 18.8981 19.8484 19.0447 19.7916 19.1814C19.7348 19.3182 19.6516 19.4424 19.5467 19.5469C19.3344 19.7564 19.0481 19.8739 18.7498 19.8739C18.4515 19.8739 18.1653 19.7564 17.953 19.5469L11.9998 13.5937L6.04671 19.5469C5.83441 19.7564 5.54812 19.8739 5.24983 19.8739C4.95154 19.8739 4.66525 19.7564 4.45296 19.5469C4.34808 19.4424 4.26486 19.3182 4.20808 19.1814C4.1513 19.0447 4.12207 18.8981 4.12207 18.75C4.12207 18.6019 4.1513 18.4553 4.20808 18.3186C4.26486 18.1818 4.34808 18.0576 4.45296 17.9531L10.4061 12L4.45296 6.04687C4.24161 5.83553 4.12288 5.54888 4.12288 5.25C4.12288 5.10201 4.15203 4.95546 4.20867 4.81873C4.2653 4.682 4.34831 4.55777 4.45296 4.45312C4.5576 4.34848 4.68184 4.26547 4.81857 4.20883C4.9553 4.1522 5.10184 4.12305 5.24983 4.12305C5.54872 4.12305 5.83536 4.24178 6.04671 4.45312L11.9998 10.4062L17.953 4.45312C18.1643 4.24178 18.4509 4.12305 18.7498 4.12305C19.0487 4.12305 19.3354 4.24178 19.5467 4.45312C19.7581 4.66447 19.8768 4.95111 19.8768 5.25C19.8768 5.54888 19.7581 5.83553 19.5467 6.04687L13.5936 12L19.5467 17.9531Z"
                        fill="#0C6D6E"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.125 18.375H19.875M4.125 12.375H19.875M4.125 6.375H19.875"
                        stroke="#0D6D6E"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  )}
                </div>
              </span>
            )}
            {!user && (
              <span
                onClick={handleMenuActive}
                aria-label="menu"
                aria-expanded="false"
                className="icon is-clickable"
                role="button"
                onKeyDown={(event) => {
                  if (event.key === "Enter") handleMenuActive()
                }}
                tabIndex={0}
              >
                <div className={styles["navbar__wrapper"]}>
                  {isMenuActive ? (
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M19.5467 17.9531C19.6516 18.0576 19.7348 18.1818 19.7916 18.3186C19.8484 18.4553 19.8776 18.6019 19.8776 18.75C19.8776 18.8981 19.8484 19.0447 19.7916 19.1814C19.7348 19.3182 19.6516 19.4424 19.5467 19.5469C19.3344 19.7564 19.0481 19.8739 18.7498 19.8739C18.4515 19.8739 18.1653 19.7564 17.953 19.5469L11.9998 13.5937L6.04671 19.5469C5.83441 19.7564 5.54812 19.8739 5.24983 19.8739C4.95154 19.8739 4.66525 19.7564 4.45296 19.5469C4.34808 19.4424 4.26486 19.3182 4.20808 19.1814C4.1513 19.0447 4.12207 18.8981 4.12207 18.75C4.12207 18.6019 4.1513 18.4553 4.20808 18.3186C4.26486 18.1818 4.34808 18.0576 4.45296 17.9531L10.4061 12L4.45296 6.04687C4.24161 5.83553 4.12288 5.54888 4.12288 5.25C4.12288 5.10201 4.15203 4.95546 4.20867 4.81873C4.2653 4.682 4.34831 4.55777 4.45296 4.45312C4.5576 4.34848 4.68184 4.26547 4.81857 4.20883C4.9553 4.1522 5.10184 4.12305 5.24983 4.12305C5.54872 4.12305 5.83536 4.24178 6.04671 4.45312L11.9998 10.4062L17.953 4.45312C18.1643 4.24178 18.4509 4.12305 18.7498 4.12305C19.0487 4.12305 19.3354 4.24178 19.5467 4.45312C19.7581 4.66447 19.8768 4.95111 19.8768 5.25C19.8768 5.54888 19.7581 5.83553 19.5467 6.04687L13.5936 12L19.5467 17.9531Z"
                        fill="#0C6D6E"
                      />
                    </svg>
                  ) : (
                    <svg
                      width="32"
                      height="32"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.125 18.375H19.875M4.125 12.375H19.875M4.125 6.375H19.875"
                        stroke="#0D6D6E"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  )}
                </div>
              </span>
            )}
          </div>
        </div>

        <div
          className={classNames("navbar-menu", styles["navbar__menu"], {
            "is-active": isMenuActive,
          })}
        >
          <div
            className={classNames(
              "navbar-end mr-1",
              styles["navbar__alignCenter"]
            )}
          >
            <AuthMobileUser
              userData={userData}
              medstaffData={medstaffData}
              handleMenuActive={handleMenuActive}
            />
            {!pageContext?.disableNavbarElements && (
              <AuthActions
                user={user}
                medstaffData={medstaffData}
                userData={userData}
                handleSignOut={handleSignOut}
              />
            )}
          </div>
        </div>
      </nav>
      <div
        role="button"
        tabIndex={0}
        aria-label="Navbar Background"
        onKeyDown={(event) => {
          if (event.key === "Enter") setMenuActive(false)
        }}
        className={classNames({
          [styles["navbar__backgroundIsActive"]]: isMenuActive,
        })}
        onClick={() => setMenuActive(false)}
      />
    </Fragment>
  )
}

export default Navbar
