import React, { Fragment, useContext, useEffect } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import Zendesk from "react-zendesk"
import { navigate } from "gatsby"
import { isBrowser } from "../../services/general"
import SEO from "./SEO"
import Container from "./Container"
import Navbar from "./Navbar"
import HelpCenterBanner from "../StaticPages/HelpCenter/HelpCenterBanner"
import Footer from "./Footer"
import PrivateRoute from "./PrivateRoute"
import PromptWellnessSurveyModal from "./PromptWellnessSurveyModal"
// import ProgressBar from "elements/ProgressBar"

import zendeskSettings from "./utils/zendeskSettings.json"

import { isLatestSurveyStillValid } from "./services/healthSurvey"
import {
  hasRoles,
  getSignedInUser,
  hasSignedInUser,
  isProfileComplete,
} from "components/Auth/services/user"
import { decodePermissions } from "components/Auth/services/signin"
import AuthRoute from "../Auth/AuthRoute"
import { AppContext } from "../../context/AppContext"
import EditProfileModal from "./EditProfileModal"

import styles from "./utils/layout.module.scss"

/**
 * @param {string} seoTitle string
 * @param {string} title string
 * @param {string} subtitle string
 * @param {array} path array
 * @param {object} display settings to display footer/help banner
 * @param {} children
 */

const Layout = ({
  children,
  title,
  subtitle,
  seoTitle,
  display = { footer: true, helpCenterBanner: true },
  path,
  isPrivate,
  pageContext,
  progress,
  locationState,
  polygonStyle = null,
  ...props
}) => {
  let pathArray = path ? path.split("/") : []

  const { userData } = getSignedInUser()
  const accessRule = getSignedInUser().accessRule
  const { dispatch } = useContext(AppContext)

  const disableChat = !!accessRule?.disableChat || props.isDisableChat

  let permissions = userData?.permissions

  if (permissions?.length > 0)
    permissions = decodePermissions(permissions) || []

  if (pageContext?.module?.name === "medensure") {
    if (isBrowser()) {
      let validOrganizations = ["sitel", "maxicare", "accenture"]

      // Get organization from session storage
      let organization = sessionStorage.getItem("organization")

      // If there is no organization in session storage, check URL parameter
      if (!organization) {
        let urlParameter = new URLSearchParams(window.location.search)
        organization = urlParameter?.get("organization")

        let isOrganizationValid = validOrganizations.includes(organization)

        // If organization URL parameter is valid, save to session storage
        if (isOrganizationValid)
          sessionStorage.setItem("organization", organization)
        // If organization URL parameter is not valid, redirect to landing page
        else navigate("/")
      }
    }
  }

  useEffect(() => {
    if (pageContext?.requireCompleteProfile) {
      if (hasSignedInUser() && !isProfileComplete() && module.name !== "home") {
        navigate("/", { state: { fromRedirect: true } })
        dispatch({
          type: "SHOW_MODAL",
          payload: {
            heading: "Complete your profile",
            isCard: true,
            headerClass: `has-text-info has-background-info-light has-text-weight-bold header-size-text`,
            content: <EditProfileModal />,
          },
        })
      }
    }
  }, [pageContext, dispatch])

  useEffect(() => {
    if (hasSignedInUser() && accessRule?.requireWellnessSurvey) {
      if (pageContext?.requireWellnessSurvey) {
        if (!isLatestSurveyStillValid())
          dispatch({
            type: "SHOW_MODAL",
            payload: {
              heading: "How are you?",
              isCard: true,
              headerClass: `has-text-info has-background-info-light has-text-weight-bold header-size-text`,
              content: <PromptWellnessSurveyModal />,
            },
          })
      }
    }
    //eslint-disable-next-line
  }, [dispatch, pageContext])

  return (
    <Fragment>
      <AuthRoute>
        <PrivateRoute isPrivate={isPrivate} locationState={locationState}>
          <SEO title={seoTitle} />
          <Navbar path={pathArray} pageContext={pageContext} />
          {!!progress && (
            <progress
              className={classNames(
                "progress is-small is-info",
                { "is-hidden-mobile": !props.isMHA },
                styles["progressBar"]
              )}
              value={`${progress}`}
              max="100"
            />
          )}
          {title && (
            <Container
              isCentered
              customBackgroundColor={classNames(
                styles[props.isForms ? "formsBackground" : null]
              )}
            >
              <h1
                className={classNames(
                  "has-text-centered has-text-left-mobile mt-3 mt-0-mobile has-text-primary pt-3 px-1-mobile",
                  {
                    "pb-0": !subtitle,
                    "pb-2": !!subtitle,
                    "mx-1 form-title": progress,
                    "pb-5 pb-2-mobile": props.isForms,
                  },
                  styles["assesment_title"]
                )}
              >
                {title}
                <p
                  className={classNames(
                    "has-text-left-mobile has-text-grey has-text-centered has-text-weight-normal",
                    styles["assessment_subtitle"]
                  )}
                >
                  {subtitle}
                </p>
              </h1>
            </Container>
          )}
          <main
            className={classNames(
              "is-relative",
              { "px-1-mobile": !props.isMHA },
              styles[`polygons__${polygonStyle}`],
              {
                "mx-1": progress && !props.isMHA,
                "my-4": props.isStaticPages,
              }
            )}
          >
            {children}
          </main>
          {display.helpCenterBanner && (
            <HelpCenterBanner module={pageContext?.module?.name} />
          )}
          {hasRoles() && permissions?.includes("SOLMH") && !disableChat && (
            <Zendesk
              zendeskKey={process.env.GATSBY_ZENDESK_MH_CHAT_ID}
              {...zendeskSettings}
            />
          )}
          {display.footer && <Footer pageContext={pageContext} />}
        </PrivateRoute>
      </AuthRoute>
    </Fragment>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  seoTitle: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  path: PropTypes.array,
}

export default Layout
