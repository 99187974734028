import React, { Fragment } from "react"
import { navigate, Link } from "gatsby"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUser } from "@fortawesome/free-solid-svg-icons"

import styles from "../utils/layout.module.scss"
import { getUserAccessCode } from "../../Auth/services/user"

const AuthActions = ({ user, userData, handleSignOut, medstaffData }) => {
  const isEnrolled = () => !!user && !!userData?.authUid
  const accessCode = getUserAccessCode()

  if (isEnrolled()) {
    return (
      <Fragment>
        <div className="navbar-item">
          <button
            className="button is-primary is-fullwidth mt-1 is-centered is-hidden-desktop button-size"
            onClick={handleSignOut}
          >
            Sign Out
          </button>
        </div>
        <div className="navbar-item has-dropdown is-hoverable is-hidden-touch">
          <span className="navbar-link">
            <FontAwesomeIcon icon={faUser} />
            <span className="navbar-name ml-1">
            </span>
          </span>
          <div className="navbar-dropdown">
            {accessCode?.disableProfile ? null : (
              <span
                aria-hidden="true"
                className={classNames(
                  "navbar-item is-clickable",
                  styles["navbar__item"]
                )}
                onClick={() => navigate("/profile")}
              >
                Profile
              </span>
            )}
            <span
              aria-hidden="true"
              className={classNames(
                "navbar-item is-clickable",
                styles["navbar__item"]
              )}
              onClick={handleSignOut}
            >
              Sign Out
            </span>
          </div>
        </div>
      </Fragment>
    )
  } else if (!!user && !userData?.authUid) {
    // During enrollment
    return (
      <Fragment>
        <div className="navbar-item">
          <button
            className="button is-primary is-fullwidth mt-1 is-centered is-hidden-desktop"
            onClick={handleSignOut}
          >
            Sign Out
          </button>
        </div>
        <div className="navbar-item has-dropdown is-hoverable is-hidden-touch">
          <span className="navbar-link">
            <FontAwesomeIcon icon={faUser} />
            <span className="navbar-name ml-1">{user?.email?.split("@")[0]}</span>
          </span>
          <div className="navbar-dropdown">
            <span
              aria-hidden="true"
              className={classNames(
                "navbar-item is-clickable",
                styles["navbar__item"]
              )}
              onClick={handleSignOut}
            >
              Sign Out
            </span>
          </div>
        </div>
      </Fragment>
    )
  } else {
    // Signed out
    if (medstaffData) return null
    return (
      <Link className={classNames(styles["signInButton"])} to="/sign-in">
        <button
          className={classNames(
            "navbar-item is-hidden-mobile button is-primary is-inverted is-outlined"
          )}
        >
          Sign in
        </button>
      </Link>
    )
  }
}

export default AuthActions
