import React, { useContext } from "react"
import { navigate } from "gatsby"
import Button from "elements/Button"

import { AppContext } from "context/AppContext"

const EditProfileModal = () => {
  const { dispatch } = useContext(AppContext)

  const handleClick = () => {
    navigate("/profile/edit")
    dispatch({ type: "HIDE_MODAL" })
  }

  return (
    <div className="has-text-left">
      <p className="mb-1">
        You have to complete your profile before we can set you up with a mental
        health professional. Please click the button below to start.
      </p>
      <center>
        <Button className="button button-size is-secondary" onClick={handleClick}>
          Complete Profile
        </Button>
      </center>
    </div>
  )
}

export default EditProfileModal
