import React, { useContext } from "react"
import { navigate } from "gatsby"
import ActionButtons from "elements/ActionButtons"

import { getLatestHealthSurvey } from "./services/healthSurvey"

import { AppContext } from "context/AppContext"

import { getSignedInUser } from "../Auth/services/user"

const PromptWellnessSurveyModal = () => {
  const { dispatch } = useContext(AppContext)
  const handleProceedClick = () => {
    dispatch({ type: "HIDE_MODAL" })
    navigate("/wellness-survey-1")
  }

  const { userData } = getSignedInUser()

  const handleCloseModal = () => {
    dispatch({
      type: "HIDE_MODAL",
    })
  }

  const latestSurvey = getLatestHealthSurvey()

  const message = !latestSurvey ? (
    <p>
      Hi {`${userData?.firstName}`}! <br />
      Your wellbeing matters. Let us know how we can best help you by completing
      this 20-minute Wellness Survey.
    </p>
  ) : (
    <p>
      How have you been, {`${userData?.firstName}`}? <br />
      Let us know how you are doing by answering this Wellness Survey.
    </p>
  )

  return (
    <div className="has-text-left">
      <p className="mb-1">{message}</p>

      <div className="mt-2 mt-0-mobile">
        <ActionButtons
          back={{
            label: (
              <svg
                width="100%"
                height="100%"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.0664 26.5599L11.3731 17.8666C10.3464 16.8399 10.3464 15.1599 11.3731 14.1333L20.0664 5.43994"
                  stroke="#EE7423"
                  stroke-width="2.66667"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            ),
            callback: handleCloseModal,
          }}
          next={{ label: "Proceed", callback: handleProceedClick }}
        />
      </div>
    </div>
  )
}

export default PromptWellnessSurveyModal
