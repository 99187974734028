import React from "react"
import { Link } from "gatsby"
import classNames from "classnames"

import { getUserAccessCode } from "../../Auth/services/user"

import styles from "../utils/layout.module.scss"

const MobilePersonalDetails = ({ userData, handleMenuActive }) => {
  const accessCode = getUserAccessCode()

  if (userData?.authUid)
    return (
      <div className="p-1 is-hidden-desktop">
        <h3 className="has-text-primary has-text-weight-bold mb-0">
          {`${
            userData?.firstName.length > 12
              ? userData?.firstName?.[0] + "."
              : userData?.firstName
          } ${userData?.lastName}`}
        </h3>
        <p className="subtitle">{userData?.email}</p>
        {accessCode?.disableProfile ? null : (
          <Link
            className="label-size-input has-text-primary"
            to="/profile"
            onClick={handleMenuActive}
          >
            <span
              className={classNames(
                "is-clickable",
                styles["navbar__personalDetailsLink"]
              )}
            >
              View profile {">"}
            </span>
          </Link>
        )}
      </div>
    )
  return (
    <div className="p-1 is-hidden-desktop">
      <Link
        to="/privacy-policy"          //change this after updating the static pages
        onClick={handleMenuActive}
      >
        <h3 className="has-text-primary mb-1">
          Privacy Policy
        </h3>
      </Link>
      <Link
        to="/help-center#contact-us" //change this after updating the static pages
        onClick={handleMenuActive}
      >
        <h3 className="has-text-primary">
          Contact Us
        </h3>
      </Link>
    </div>
  )
}

export default MobilePersonalDetails
