import React from "react"
import { Link } from "gatsby"
import classNames from "classnames"
import { faUser } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { getUserAccessCode } from "../../Auth/services/user"

const AuthActionsMobile = ({
  user,
  userData,
  isMenuActive,
  medstaffData = null,
}) => {
  const accessCode = getUserAccessCode()

  if (user && userData?.authUid)
    return (
      <Link
        className={classNames(
          "navbar-item py-0 mr-1 px-1 has-text-centered button is-primary is-inverted is-outlined",
          {
            "is-hidden": isMenuActive,
          }
        )}
        to={accessCode?.disableProfile ? "/" : "/profile"}
      >
        <FontAwesomeIcon icon={faUser} className="label-size-input" />
        <span className="ml-1 label-size-input">
          {userData?.firstName && userData?.lastName
            ? userData?.firstName + " " + userData?.lastName?.[0] + "."
            : user?.email?.split("@")[0]}
        </span>
      </Link>
    )

  if (user && !userData?.authUid)
    return (
      <span
        className={classNames(
          "navbar-item mr-1 px-1 has-text-centered button is-primary is-inverted is-outlined",
          {
            "is-hidden": isMenuActive,
          }
        )}
      >
        <span>{user?.email?.split("@")[0]}</span>
      </span>
    )

  if (medstaffData) return null

  return (
    <Link
      className={classNames(
        "navbar-item button mr-1 px-1 mb-0 is-primary is-inverted is-outlined",
        {
          "is-hidden": isMenuActive,
        }
      )}
      to="/sign-in"
    >
      Sign in
    </Link>
  )
}

export default AuthActionsMobile
