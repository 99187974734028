import React, { Fragment } from "react"
import Loading from "elements/Loading"
import { navigate } from "gatsby"
import { isBrowser } from "../../services/general"
import { hasRoles, hasSignedInUser } from "../Auth/services/user"

const PrivateRoute = ({ isPrivate, children, locationState }) => {
  if (isPrivate && isBrowser()) {
    if (hasSignedInUser() && !hasRoles()) {
      navigate("/enrollment")
    }
    // else if (locationState) {
    //   navigate("/sign-in", { state: locationState })
    // }
    else if (!hasSignedInUser()) {
      locationState ? navigate("/verify-email", { state:locationState }) : navigate("/verify-email")
      return <Loading />
    }
  }

  return <Fragment>{children}</Fragment>
}

export default PrivateRoute

// useEffect(() => {
//   if (IS_AUTH_ENABLED && !isOrderingAsGuest) {
//     if (isPrivate && isBrowser() && !hasSignedInUser()) {
//       navigate("/sign-in")
//       locationState ? navigate("/sign-in", { state: locationState }) : navigate("/sign-in")
//       return <Loading />
//     }
//     if (isPatient && isBrowser() && hasSignedInUser()) {
//       let user = getSignedInUser()
// @@ -85,22 +88,26 @@ const Layout = ({
// }, [])