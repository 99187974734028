import { getSignedInUser } from "../../Auth/services/user"

import moment from "moment"

const getLatestSurveyDate = ({ surveys }) => {
  return new Date(
    Math.max(...surveys.map((survey) => new Date(survey.created)))
  )
}
export const getLatestHealthSurvey = () => {
  const { healthSurveys } = getSignedInUser()

  const latestSurveyDate = getLatestSurveyDate({ surveys: healthSurveys })

  const latestSurvey = healthSurveys.find((survey) => {
    return new Date(survey.created).valueOf() === latestSurveyDate.valueOf()
  })

  return latestSurvey
}

export const isLatestSurveyStillValid = () => {
  const latestSurvey = getLatestHealthSurvey()
  if (latestSurvey) {
    return moment(latestSurvey.validUntil).isAfter()
  } else return false
}
