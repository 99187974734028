import firebase from "firebase"
import { navigate } from "gatsby"
import { hasSignedInUser } from "./user"
import { isBrowser } from "services/general"
import axios from "axios"
import { GATSBY_AUTH } from "gatsby-env-variables"

export const handleSignOut = async ({ redirect = true, error }) => {
  try {
    firebase.auth().signOut()
    await axios(GATSBY_AUTH + "/logout", {
      method: "post",
      withCredentials: true,
    })
  } catch (error) {
    console.error("Sign Out Error", error)
  }

  if (isBrowser()) {
    sessionStorage.removeItem("userData")
    sessionStorage.removeItem("addresses")
    sessionStorage.removeItem("medstaffData")
    sessionStorage.removeItem("contextState")
    sessionStorage.removeItem(hasSignedInUser())
    sessionStorage.removeItem("programChosen")
  }
  if (redirect) {
    if (window.location.pathname) window.location.reload()
    else return navigate("/sign-in")
  } else if (!error) return navigate("/")
  else return
}
