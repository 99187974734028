import React from "react"
import { useStaticQuery, graphql, Link, navigate } from "gatsby"
import Img from "gatsby-image"
import classNames from "classnames"
import { camelize } from "humps"

import Container from "layout/Container"
import sitemap from "./utils/sitemap.json"
import {
  getSignedInUser,
  hasSignedInUser,
  isProfileComplete,
} from "components/Auth/services/user"

import styles from "./utils/layout.module.scss"

const Footer = ({ pageContext }) => {
  const currentYear = new Date().getFullYear()
  const data = useStaticQuery(graphql`
    {
      logoLong: file(relativePath: { eq: "logos/medgrocer-long.png" }) {
        childImageSharp {
          fixed(height: 25) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const logoLong = data.logoLong.childImageSharp.fixed

  const Logo = () => {
    return pageContext?.disableFooterElements ? (
      <Img fixed={logoLong} />
    ) : (
      <Link to="/">
        <Img fixed={logoLong} />
      </Link>
    )
  }

  if (!hasSignedInUser() || !isProfileComplete()) {
    sitemap.forEach((section, index) => {
      if (section.section !== "Get Started") {
        section.links = section.links.filter(
          (link) =>
            link.label !== "Terms and Conditions" &&
            link.label !== "Informed Consent"
        )
      } else {
        sitemap.splice(index, 1)
      }
    })
  }

  const accessRule = getSignedInUser().accessRule

  const handleOnClick = (link) => {
    let linkLabel = camelize(link.label)

    if (accessRule?.redirectLinks && linkLabel in accessRule?.redirectLinks) {
      window.location.href = `https://${accessRule?.redirectLinks[linkLabel]}`
    } else {
      navigate(`/${link.url}`)
    }
  }

  return (
    <footer className="footer px-1">
      <Container isCentered mobile={12} tablet={10} desktop={10} fullhd={8}>
        <div className="columns is-centered">
          <div className="column is-6 pl-0 pr-2 px-1-mobile">
            <Logo />
            <p className="footer-helper">
            MedGrocer's digitally-enabled corporate healthcare solutions and patient programs empower our customers to get their medicines, vaccines, and teleconsultations conveniently, cost-effectively, and intelligently.
            </p>
            <p className="footer-helper pt-1">
            Every year, we serve over one million Filipinos either directly or through partnerships with employers and pharmaceutical companies.
            </p>

            <p className="footer-helper pt-1">
              © {currentYear}, MedGrocer · MedGrocer (MG Health Solutions, Inc.)
            </p>
          </div>

          {!pageContext?.disableFooterElements &&
            sitemap.map((section, index) => (
              <div
                className={classNames(section.classnames, "column is-2 pl-0 px-1-mobile")}
                key={index}
              >
                <p className="mt-0 has-text-primary has-text-weight-bold">{section.section}</p>
                <div className="footer-links-wrapper">
                  {section.links.map((link) => (
                    <button
                      className={classNames(
                        "footer-helper has-text-secondary has-text-weight-bold is-underlined",
                        styles["button_link"]
                      )}
                      onClick={() => handleOnClick(link)}
                    >
                      {link.label}
                    </button>
                  ))}
                </div>
              </div>
            ))}
        </div>
      </Container>
    </footer>
  )
}

export default Footer
